// import './css/fullpage.css';
// import './css/mapbox-gl.css';
// import 'swiper/swiper-bundle.css';
// import './css/tw-base.css';
// import './css/typography.css';
// import './css/tw-other.css';
// import './css/fullpage-custom.css';
// import './css/layout.css';
// import './css/swiper.css';

import './css/main.css';

// import './js/shader-web-background.min.js';

var imagesLoaded = require('imagesloaded');

import lazySizes from 'lazysizes';

import Isotope from 'isotope-layout';

import 'isotope-packery';

import Cookies from 'js-cookie';

import './js/modernizr-custom.js';


import scrollHorizontally from './js/fullpage.scrollHorizontally.min.js';
// import dragAndMove from './js/fullpage.dragAndMove.min.js';
import scrollOverflow from './js/fullpage.scrolloverflow.min.js';
import parallax from './js/fullpage.parallax.min.js';
import responsiveSlides from './js/fullpage.responsiveSlides.min.js';
import continuousHorizontal from './js/fullpage.continuousHorizontal.min';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
// var Granim = require('granim');

import truncatise from 'truncatise';

import Plyr from 'plyr';



import InfiniteScroll from 'infinite-scroll';
var imagesLoaded = require('imagesloaded');
InfiniteScroll.imagesLoaded = imagesLoaded;


// import BackgroundCheck from './js/background-check.min.js';
// import imagesLoaded from 'imagesloaded';


// BackgroundCheck.init({
//     targets: '.backgroundcheck-text',
//     images: '.backgroundcheck-images'
// });

// imagesLoaded( '#fullpage', function() {

//     console.log("REFRESH");
// });



// ------------------------------ Lazy sizes config ------------------------------

lazySizes.cfg.lazyClass = 'lazysize';




// ------------------------------  Cookie consent ------------------------------

function setCookie() {
    Cookies.set('cookieconsent', 'hasconsent', {
        expires: 90,
        path: "/"
    });
    document.getElementById("cookie-consent").classList.add("opacity-0");
    setTimeout(function () {
        document.getElementById("cookie-consent").classList.add("hidden")
    }, 500);
};

window.setCookie = setCookie;

function showCookieConsent() {

    var cookieValue = Cookies.get('cookieconsent');

    console.log("Cookie Consent: " + cookieValue);

    if (cookieValue === "hasconsent") {

    } else {
        document.getElementById("cookie-consent").classList.remove("opacity-0");
        document.getElementById("cookie-consent").classList.remove("hidden");
    };
};



// ------------------------------  Front Page ------------------------------

function showBannerImage(bannerDIV) {
    const div = document.getElementById(bannerDIV);
    div.classList.remove("opacity-0");
    div.classList.add("opacity-50");
};

function hideBannerImage(bannerDIV) {
    const div = document.getElementById(bannerDIV);
    div.classList.add("opacity-0");
    div.classList.remove("opacity-50");
};

window.showBannerImage = showBannerImage;
window.hideBannerImage = hideBannerImage;



// ------------------------------  Mobile front page banner ------------------------------

var homepageBanner = document.getElementsByClassName("banner-text");
var mediaMobile = window.matchMedia("(max-width: 640px)")
if (homepageBanner.length > 0 && mediaMobile.matches) {

    function showSlide(number) {
        hideSlides();
        var text = document.getElementById("banner-image-" + number + "-text");
        var div = "banner-image-" + number;
        text.classList.add("text-mc-green");
        showBannerImage(div);
    };

    function hideSlides() {
        var texts = document.getElementsByClassName("banner-text");
        var images = document.getElementsByClassName("banner-image");
        var t;
        for (t = 0; t < texts.length; t++) {
            images[t].classList.add("opacity-0");
            images[t].classList.remove("opacity-50");
            texts[t].classList.remove("text-mc-green");
        }
    };

    function slideCycle() {
        showSlide(1);
        setTimeout(function () {
            showSlide(2)
        }, 4000);
        setTimeout(function () {
            showSlide(3)
        }, 8000);
        setTimeout(function () {
            showSlide(4)
        }, 12000);
    };


    slideCycle();
    setInterval(function () {
        slideCycle()
    }, 16000);

    console.log("Doing a homepage cycle");
} else false;




// other stuff


var fullpagediv = document.getElementsByClassName("fullpage");
if (fullpagediv.length > 0) {

    var tooltiparray = [];

    var i;
    var thesections = document.getElementsByClassName("section");
    for (i = 0; i < thesections.length; i++) {
        var singletooltip = thesections[i].getAttribute("data-tooltips");

        tooltiparray.push(singletooltip);

    };

    var thefullpage = new fullpage('#fullpage', {

        licenseKey: 'B0F92FE5-9DB34B57-86A089EA-5394E22B',
        controlArrows: true,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: tooltiparray,
        slidesNavigation: false,
        scrollOverflow: true,
        // normalScrollElements: '.scrollable-content',
        parallax: true,
        parallaxKey: 'bWlsbGNvLmNvLnVrX0lsVWNHRnlZV3hzWVhnPTVRQQ==',
        parallaxOptions: {
            type: 'cover',
            percentage: 35,
            property: 'translate'
        },
        continuousHorizontal: true,
        continuousHorizontalKey: 'bWlsbGNvLmNvLnVrXzM2MVkyOXVkR2x1ZFc5MWMwaHZjbWw2YjI1MFlXdz1HM3I=',
        scrollOverflowOptions: {
            disablePointer: true,
        },
        responsiveSlides: true,
        responsiveSlidesKey: 'bWlsbGNvLmNvLnVrX2xiM2NtVnpjRzl1YzJsMlpWTnNhV1JsY3c9PXZNdA==',
        slidesToSections: true,
        responsiveWidth: 769,
        scrollOverflowResetKey: 'bWlsbGNvLmNvLnVrXzAyNWMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT1yYk0=',
    });

    console.log(tooltiparray);

} else false;

// ! can we lose this?
var fullpagedivsingle = document.getElementsByClassName("fullpagesingle");
if (fullpagedivsingle.length > 0) {

    var thefullpage = new fullpage('#fullpagesingle', {

        licenseKey: 'B0F92FE5-9DB34B57-86A089EA-5394E22B',
        controlArrows: true,
        navigation: false,
        // autoScrolling: false,
        // fitToSection: false,
        navigationPosition: 'right',
        slidesNavigation: false,
        scrollOverflow: true,
        // normalScrollElements: '.scrollable-content',
        parallax: true,
        parallaxKey: 'bWlsbGNvLmNvLnVrX0lsVWNHRnlZV3hzWVhnPTVRQQ==',
        parallaxOptions: {
            type: 'cover',
            percentage: 35,
            property: 'translate'
        },
        scrollOverflowOptions: {
            // disablePointer: true,
            fitToSection: false,
            "mouseWheelSpeed": 5,
        },
        continuousHorizontal: true,
        continuousHorizontalKey: 'bWlsbGNvLmNvLnVrXzM2MVkyOXVkR2x1ZFc5MWMwaHZjbWw2YjI1MFlXdz1HM3I=',
        responsiveSlides: true,
        responsiveSlidesKey: 'bWlsbGNvLmNvLnVrX2xiM2NtVnpjRzl1YzJsMlpWTnNhV1JsY3c9PXZNdA==',
        slidesToSections: true,
        responsiveWidth: 640,
        scrollOverflowResetKey: 'bWlsbGNvLmNvLnVrXzAyNWMyTnliMnhzVDNabGNtWnNiM2RTWlhObGRBPT1yYk0=',
    });

} else false;

// var granimInstance = new Granim({
//     element: '#granim-canvas',
//     name: 'granim',
//     direction: 'top-bottom',
//     opacity: [1, 1],
//     states : {
//         "default-state": {
//             gradients: [
//                  [
//                      { color: 'hsla(3, 58%, 52%, 1)', pos: .2 },
//                      { color: 'hsla(26, 48%, 79%, 1)', pos: .3 },
//                      { color: 'hsla(254, 100%, 50%, 1)', pos: 1 }
//                  ], [
//                      { color: 'hsla(247, 100%, 47%, 1)', pos: 0 },
//                      { color: 'hsla(180, 67%, 26%, 1)', pos: .7 },
//                      { color: 'hsla(3, 58%, 52%, 1)', pos: .9 }
//                  ],
//              ]
//         }
//     }
//  });







// ------------------------------  Main Menu ------------------------------



document.getElementById("menu-button").addEventListener('click', (e) => {

    const menu = document.getElementById("menu");
    const button = document.getElementById("menu-button");

    if (button.getAttribute("data-menu-status") == "closed") {
        button.innerHTML = "CLOSE";
        button.setAttribute("data-menu-status", "open");
        menu.classList.remove("hidden");
        setTimeout(function () {
            menu.classList.remove("opacity-0")
        }, 0);
    } else {
        button.innerHTML = "MENU";
        button.setAttribute("data-menu-status", "closed");
        menu.classList.add("opacity-0");
        setTimeout(function () {
            menu.classList.add("hidden")
        }, 500);
    };

});


// ------------------------------  Truncate ------------------------------


var trunc = document.getElementsByClassName("truncate-this");
if (trunc.length > 0) {
    var w;
    var truncOptions = {
        TruncateLength: 50,
        TruncateBy: "words",
        Strict: false,
        StripHTML: false,
        Suffix: '...'
    };
    for (w = 0; w < trunc.length; w++) {
        var text = trunc[w].innerHTML;
        var newtext = truncatise(text, truncOptions);
        console.log("NEW: " + newtext)
        trunc[w].innerHTML = newtext;
    };
} else false;


// ------------------------------  Custom video player ------------------------------


var vids = document.getElementsByClassName("videoplayer");
if (vids.length > 0) {

    const players = Array.from(document.querySelectorAll('.videoplayer')).map(p => new Plyr(p, {
        controls: [
            // 'play-large', // The large play button in the center
            // 'restart', // Restart playback
            //       'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
            //       'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            //       'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            'airplay', // Airplay (currently Safari only)
            //       'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            'fullscreen' // Toggle fullscreen
        ],
        autoplay: false,
        clickToPlay: true,
        fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true
        }
    }));
};



// ------------------------------  Newsletter signup ------------------------------

function openNewsletterSignup() {
    var modal = document.getElementById("newsletter-modal");
    modal.classList.remove("hidden");
    setTimeout(function () {
        modal.classList.remove("opacity-0")
    }, 100);
};

function closeNewsletterSignup() {
    var modal = document.getElementById("newsletter-modal");
    modal.classList.add("opacity-0");
    setTimeout(function () {
        modal.classList.add("hidden");
    }, 500);
};

window.openNewsletterSignup = openNewsletterSignup;
window.closeNewsletterSignup = closeNewsletterSignup;



// ------------------------------  Register form ------------------------------

function openStudiosSignup() {
    closeSpaceHire();
    closeContactForm();
    var modal = document.getElementById("studios-modal");
    modal.classList.remove("hidden");
    setTimeout(function () {
        modal.classList.remove("opacity-0")
    }, 100);
};

function closeStudiosSignup() {
    var modal = document.getElementById("studios-modal");
    modal.classList.add("opacity-0");
    setTimeout(function () {
        modal.classList.add("hidden");
    }, 500);
};

window.openStudiosSignup = openStudiosSignup;
window.closeStudiosSignup = closeStudiosSignup;



// ------------------------------  Space hire form ------------------------------

function openSpaceHire() {
    closeStudiosSignup();
    closeContactForm();
    var modal = document.getElementById("space-hire-modal");
    modal.classList.remove("hidden");
    setTimeout(function () {
        modal.classList.remove("opacity-0")
    }, 100);
};

function closeSpaceHire() {
    var modal = document.getElementById("space-hire-modal");
    modal.classList.add("opacity-0");
    setTimeout(function () {
        modal.classList.add("hidden");
    }, 500);
};

window.openSpaceHire = openSpaceHire;
window.closeSpaceHire = closeSpaceHire;


// ------------------------------  Contact form ------------------------------

function openContactForm() {
    closeSpaceHire();
    closeStudiosSignup();
    var modal = document.getElementById("contact-modal");
    modal.classList.remove("hidden");
    setTimeout(function () {
        modal.classList.remove("opacity-0")
    }, 100);
};

function closeContactForm() {
    var modal = document.getElementById("contact-modal");
    modal.classList.add("opacity-0");
    setTimeout(function () {
        modal.classList.add("hidden");
    }, 500);
};

window.openContactForm = openContactForm;
window.closeContactForm = closeContactForm;




// ------------------------------  Isotope ------------------------------

var isogrid = document.getElementsByClassName("iso-grid");
if (isogrid.length > 0) {

        document.addEventListener('lazyloaded', function(e){
            if (e.target.parentElement.classList.contains("imagebackground")) {
                setTimeout(function() {e.target.parentElement.style.backgroundColor = "white"}, 1000);
            } else {
                setTimeout(function() {e.target.style.backgroundColor = "white"}, 1000);
            };
        });

        var elem = document.querySelector('.iso-grid');
        var iso = new Isotope('.iso-grid', {
            // options
            itemSelector: '.grid-item',
            layoutMode: 'packery',
            stamp: '.stamp'
        });


        function filterUpdate(category) {

            var filterButts = document.getElementsByClassName("filter-buttons");
            var e;
            for (e = 0; e < filterButts.length; e++) {
                filterButts[e].classList.remove("text-mc-green");
            };

            if (category == '*') {
                var filterElem = category;
                document.getElementById("filter-all").classList.add("text-mc-green");
            } else {
                var filterElem = '.' + category;
                document.getElementById('filter-' + category).classList.add("text-mc-green");
            };


            console.log(category);
            iso.arrange({
                filter: filterElem
            });
            setTimeout(function () {
                iso.layout(), 500
            });

        };

        window.filterUpdate = filterUpdate;

        function showItems () {
            var grid = document.getElementsByClassName("iso-grid");
            var i;
            for (i = 0; i < grid.length; i++) {
                grid[i].style.opacity = 1;
            };
        };
      
        showItems();
      
      
        imageHeightFix();
      
        iso.layout();



        let path = window.location.pathname;
        let searchpath = window.location.search;
        console.log(searchpath);
        // console.log("Path" + path);

        // console.log(infscrollelem);
        if (isogrid[0].classList.contains("infinite1")) {
            let infScroll = new InfiniteScroll( isogrid[0], {
            // options
            path: path + 'page/{{#}}/' + searchpath ,
            append: '.grid-item',
            history: false,
            outlayer: iso,
            });

            infScroll.on( 'append', function() {
                imageHeightFix();
                iso.layout();
            });
        } else false;


} else false;


var isogrid2 = document.getElementsByClassName("iso-grid2");
if (isogrid2.length > 0) {

        var elem2 = document.querySelector('.iso-grid2');
        var iso2 = new Isotope('.iso-grid2', {
            // options
            itemSelector: '.grid-item2',
            layoutMode: 'packery',
            stamp: '.stamp2'
        });


        function filterUpdatePast(category) {
            iso2.arrange({
                filter: category
            });
            setTimeout(function () {
                iso2.layout(), 500
            });
        };

        window.filterUpdatePast = filterUpdatePast;


    function showItems3 () {
        var grid = document.getElementsByClassName("iso-grid2");
        var i;
        for (i = 0; i < grid.length; i++) {
            grid[i].style.opacity = 1;
            layoutTheGrid3();
        };
    };
    
    showItems3();
    
    imageHeightFix();
    
    function layoutTheGrid3 () { iso2.layout() };

} else false;



function imageHeightFix () {

    if (CSS.supports("aspect-ratio: 1 / 1")) {

        var images = document.getElementsByClassName("lazysize");
        var i;
        for (i=0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
            
           

            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight/imgwidth)*containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight/imgwidth)*containerwidth;
                images[i].style.height = newheight + 'px';
            };  

             console.log(newheight);

        };

        document.addEventListener('lazyloaded', function(e){
            if (e.target.parentElement.classList.contains("imagebackground")) {
                    e.target.parentElement.style.height = 'auto';
            } else {
                    e.target.style.height = 'auto';
            };
        });

    } else {
        var images = document.getElementsByClassName("lazysize");
        var i;
        for (i=0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
            
           

            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight/imgwidth)*containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight/imgwidth)*containerwidth;
                images[i].style.height = newheight + 'px';
            };  

             console.log(newheight);

        };

        document.addEventListener('lazyloaded', function(e){
            if (e.target.parentElement.classList.contains("imagebackground")) {
                    e.target.parentElement.style.height = 'auto';
            } else {
                    e.target.style.height = 'auto';
            };
        });
    };
};










// ------------------------------  Fade in when content loaded ------------------------------

window.addEventListener('DOMContentLoaded', (event) => {
    var content = document.getElementById("main-content");

    content.classList.remove("opacity-0");
});




// ------------------------------  FAQ Dropdowns ------------------------------

function dropdownToggle(container) {
    // console.log(container);
    let horizontal = container.querySelector('.dropdown-plus-horizontal');
    horizontal.classList.toggle("dropdown-plus-horizontal-active");
    let vertical = container.querySelector('.dropdown-plus-vertical');
    vertical.classList.toggle("dropdown-plus-vertical-active");

    let panel = container.querySelector('.dropdown-content');

    console.log(panel);

    if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
    } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
    }
};

window.dropdownToggle = dropdownToggle;


// ------------------------------  Firefox blur hack ------------------------------

function checkBackground() {
    var background = document.body.getAttribute("style");
    console.log(background);
    if (CSS.supports("backdrop-filter: blur(2px)")) {
        console.log("Backdrops")
    } else {
        console.log("No Backdrops")
        var backdrops = document.getElementsByClassName("backdrop-filter");
        var t;
        for (t = 0; t < backdrops.length; t++) {
            backdrops[t].setAttribute("style", background);
            backdrops[t].classList.add("bg-fixed");
            // var bgDiv = document.createElement("div");
            // bgDiv.setAttribute("class", "firefox-bg");
            // bgDiv.setAttribute("style", background);
            // backdrops[t].appendChild(bgDiv);
        };
    };
};




window.onload = function () {
    showCookieConsent();
    checkBackground();
};